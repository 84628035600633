<template>
  <div class="setEntrance-warp">
    <div class="entrance-list">
      <h2>可选入口 <span>({{entranceListChecked.length}}/</span><span>8)</span></h2>
      <ul>
        <li v-if="entranceList[0]">
          <span>{{entranceList[0].name}}</span>
          <el-checkbox-group v-model="entranceList0Checked"
            :max="entranceListChecked.length<8?entranceList[0].children.length:entranceList0Checked.length">
            <el-checkbox v-for="item in entranceList[0].children"
              :checked="item.selected"
              :label="item.id"
              :key="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </li>
        <li v-if="entranceList[1]">
          <span>{{entranceList[1].name}}</span>
          <el-checkbox-group v-model="entranceList1Checked"
            :max="entranceListChecked.length<8?entranceList[1].children.length:entranceList1Checked.length">
            <el-checkbox v-for="item in entranceList[1].children"
              :checked="item.selected"
              :label="item.id"
              :key="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </li>
        <li v-if="entranceList[2]">
          <span>{{entranceList[2].name}}</span>
          <el-checkbox-group v-model="entranceList2Checked"
            :max="entranceListChecked.length<8?entranceList[2].children.length:entranceList2Checked.length">
            <el-checkbox v-for="item in entranceList[2].children"
              :checked="item.selected"
              :label="item.id"
              :key="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </li>
        <li v-if="entranceList[3]">
          <span>{{entranceList[3].name}}</span>
          <el-checkbox-group v-model="entranceList3Checked"
            :max="entranceListChecked.length<8?entranceList[3].children.length:entranceList3Checked.length">
            <el-checkbox v-for="item in entranceList[3].children"
              :checked="item.selected"
              :label="item.id"
              :key="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </li>
        <li v-if="entranceList[4]">
          <span>{{entranceList[4].name}}</span>
          <el-checkbox-group v-model="entranceList4Checked"
            :max="entranceListChecked.length<8?entranceList[4].children.length:entranceList4Checked.length">
            <el-checkbox v-for="item in entranceList[4].children"
              :checked="item.selected"
              :label="item.id"
              :key="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </li>
      </ul>
    </div>
    <div class="setEntrance-footer">
      <div>
        <el-button style="width: 120px;" @click="$router.push({path: '/home/system'})">返回</el-button>
        <el-button style="width: 120px;" type="primary"
          @click="saveEntrance">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        entranceList0Checked: [],
        entranceList1Checked: [],
        entranceList2Checked: [],
        entranceList3Checked: [],
        entranceList4Checked: [],
      };
    },
    computed: {
      entranceListChecked() {
        return [...this.entranceList0Checked, ...this.entranceList1Checked, ...this.entranceList2Checked, ...this.entranceList3Checked, ...this.entranceList4Checked];
      },
      entranceList() {
        return this.$store.state.entranceList;
      },
    },
    methods: {
      async saveEntrance() {
        this.$store.dispatch('saveEntrance', this.entranceListChecked);
      },
    },
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '系统首页',
          path: 'system',
        },
        {
          name: '快捷入口设置',
          path: 'entrance',
        },
      ]);
    },
  };
</script>

<style lang="less" scoped>
  .setEntrance-warp {
    background: #ffffff;
    margin: 24px;
    .entrance-list {
      width: 800px;
      margin: 0 auto;
      padding: 20px 0;
      h2 {
        font-size: 16px;
        span {
          font-size: 12px;
          color: #666;
        }
      }
      ul {
        margin-top: 14px;
        li {
          display: flex;
          justify-content: flex-start;
          padding: 10px 0;
          span {
            margin-right: 50px;
          }
          .el-checkbox {
            width: 78px;
          }
        }
      }
    }
    .setEntrance-footer {
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      height: 50px;
      width: 100%;
      > div {
        line-height: 50px;
        width: 30%;
        text-align: right;
        margin: 0 auto;
      }
    }
  }
</style>
